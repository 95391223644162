"use client";

import classNames from "classnames";

import { Button as CurrencyButton } from "@nerdwallet/currency/components/Button";

import type { ReactNode } from "react";

import styles from "./Button.module.less";

interface ButtonProps {
  children: ReactNode;
  href?: string;
  primary?: boolean;
  target?: string;
}

const Button = ({ children, href, target, primary }: ButtonProps) => {
  return (
    <div
      className={classNames(
        styles.wrapper,
        styles.accessibility,
        styles.accent
      )}
    >
      <CurrencyButton href={href} target={target} primary={primary}>
        {children}
      </CurrencyButton>
    </div>
  );
};

export default Button;
